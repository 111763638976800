import { 
    isWebStaging,
    getGA4MeasurementIdForSgtm
} from "./analytics/ga4/analytics-utils";

export function insertGtag() {
	if (typeof window.gtag !== "function" || !isWebStaging()) {
		return;
	}
	const locationOrigin = window.location.origin;
	const measurement_id = getGA4MeasurementIdForSgtm();
	const script = document.createElement("script");
	script.src = `${locationOrigin}/tag/gtag/js?id=${measurement_id}&l=newDataLayer`;
	script.type = "text/javascript";
	script.async = 1;
	document.getElementsByTagName("head")[0].appendChild(script);
	gtag("js", new Date());
    gtag("config", measurement_id, {
		"send_page_view": false,
		"server_container_url": `${locationOrigin}/tag/`,
		"debug_mode": isWebStaging()
	});
}
